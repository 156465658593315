/* eslint no-unused-vars: 0 */

import PropTypes from "prop-types";
import React from "react";
import { ThemeContext } from "../../layouts";
import { Card, Tag } from "antd";

import { GithubOutlined, GlobalOutlined, LinkOutlined } from "@ant-design/icons";
const { Meta } = Card;

const Talks = (props) => {
  const talks = [
    {
      title: "React Global Summit 2022",
      description: "I talked at the React Global Summit 2022 about adding intelligence to React apps with Azure Cognitive Services.",
      repository: "https://sahansera.dev/adding-intelligence-react-azure-cognitive/",
      technologies: ["Global Conference"],
    },
    {
      title: "Tips for Jnr Developers",
      description: "I was invited to talk at the Adelaide Jnr Developer meetup to share my 10+ year long journey as a software engineer.",
      repository: "https://sahansera.dev/tips-and-advices-for-jnr-devs/",
      technologies: ["Meetup"]
    },
    {
      title: "Go for .NET Developers",
      description:
        "This talk was delivered at the Adelaide .NET User Group meetup to introduce Go to .NET developers.",
      repository: "https://sahansera.dev/go-for-dotnet-developers/",
      technologies: ["Meetup"],
    },
    {
      title: "Introducing Blazor",
      description:
        "I co-presented this talk at the Adelaide .NET User Group meetup to introduce Blazor server and WASM technologies.",
      repository: "https://github.com/sahansera/BlazorTalk-ADNUG",
      technologies: ["Meetup"],
    },
  ];

  const renderTalks = () => {
    return (
      <div>
        {talks.map((talk, index) => (
          <Card
            title={talk.title}
            size="small"
            actions={[
              talk.link && (
                <LinkOutlined key="link" onClick={() => window.open(talk.link, "_blank")} />
              ),
              talk.repository && (
                <LinkOutlined
                  key="github"
                  onClick={() => window.open(talk.repository, "_blank")}
                />
              ),
              talk.liveDemo && (
                <GlobalOutlined
                  key="demo"
                  onClick={() => window.open(talk.liveDemo, "_blank")}
                />
              ),
            ]}
            key={index}
            style={{ marginBottom: "1rem", boxShadow: 'var(--card-shadow-color)' }}
          >
            <Meta description={talk.description} />
            <div style={{ marginTop: "1rem" }}>
              {talk.technologies.map((technology, techIndex) => (
                <Tag color="green" key={techIndex}>
                  {technology}
                </Tag>
              ))}
            </div>
          </Card>
        ))}
      </div>
    );
  };

  return (
    <React.Fragment>
      <ThemeContext.Consumer>
        {(theme) => (
          <>
            <div>
              <p style={{ marginBottom: "1rem" }}>
                I have been fortunate enough to speak at a few conferences and meetups. Following are some of them.
              </p>
              {renderTalks()}
            </div>
            <style jsx>{`
              h2 {
                margin-bottom: 0.5em;
              }

              @media (prefers-color-scheme: dark) {
                --card-shadow-color: 0.8rem 1.4rem 3.8rem rgb(245 245 245 / 6%), 1px 3px 8px rgb(39 44 49 / 3%);
              }
      
              @media (prefers-color-scheme: light) {
                --shadow-color: 0.8rem 1.4rem 3.8rem rgb(39 44 49 / 6%), 1px 3px 8px rgb(39 44 49 / 3%);
              }
            `}</style>
          </>
        )}
      </ThemeContext.Consumer>
    </React.Fragment>
  );
};

Talks.propTypes = {
  form: PropTypes.object,
};

export default Talks;
