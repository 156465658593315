import React from "react";
import { ThemeContext } from "../layouts";
import Article from "../components/Article";
import Headline from "../components/Article/Headline";
import Seo from "../components/Seo";
import Talks from "../components/Talks/Talks";

const TalksPage = () => {
  return (
    <React.Fragment>
      <ThemeContext.Consumer>
        {theme => (
          <Article theme={theme}>
            <header>
              <Headline title="Talks" theme={theme} />
            </header>
            <Talks theme={theme} />
          </Article>
        )}
      </ThemeContext.Consumer>
      <Seo />
    </React.Fragment>
  );
};

export default TalksPage;

